import React from 'react';
import { Container, Row, Col } from "react-bootstrap"

import './../../App.css';

class Payment extends React.Component {
    render() {
        document.title = 'krucerstattoos - payment';

        return (
          <div className="App">
            <Container>
              <h1 className="App-header-alt">PAYMENT</h1>
              <Row>
                <Col sm={6}>
                  <h4>CashApp</h4>
                  <a href="https://cash.app/$krucerstattoos/100.00" target="_blank" rel="noreferrer">
                  <img src="https://cash.app/qr/$krucerstattoos?size=288&margin=0" alt="CashApp Payment QR Code" className="img-thumbnail rounded mx-auto d-block img-payment"></img>
                  </a>
                </Col>
                <Col sm={6}>
                  <h4>ApplePay</h4>
                  <a href="sms://+15594969155&body=Hey Krucer - about to drop you a $100 deposit for a tattoo!" target="_blank" rel="noreferrer">
                  <img src="/apple-pay.png" alt="Apple Pay Logo" className="img-thumbnail rounded mx-auto d-block img-payment"></img>
                  </a>
                </Col>
              </Row>
            </Container>
          </div>
        );
    }
}

export default Payment;
