import React from 'react';

import './../../App.css';

class Home extends React.Component {
    render() {
        document.title = 'krucerstattoos - home';

        return (
          <div className="App-header-alt-home">
            <h1 className="h1-light-large">krucers tattoos</h1>
            <img src="logo-min.png" alt="krucers tattoos logo" className="img-fluid d-block header-image" />
            <hr />
            <ul className="inline-list h2-light-large">
              <li>
                Appointment Only
              </li>
              <li>
                Strong Fine Lines
              </li>
              <li>
                Color, Black and Grey, Custom Script, Coverups
              </li>
              <li>
                Private Tattoo Studio
              </li>
              <li>
                Non-refundable Deposit Required
              </li>
            </ul>
          </div>
        );
    }
}

export default Home;

