import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Container, Row, Col, Form, Button, InputGroup, Alert, Card } from "react-bootstrap"
import './../../App.css';
import reactDom from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

export const Waiver = () => {
    document.title = 'krucerstattoos - waiver';
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        var formData = form.current;

        formData.identification = btoa()

        emailjs.sendForm('service_v3dzmdd', 'template_gfxv09s', form.current, 'xanr2mTWRowkAJsq3')
            .then((result) => {
                const element = <Alert variant="success">
                        <Alert.Heading>Success!</Alert.Heading>
                        <p>
                            Waiver Submitted! Please present your identification at your scheduled appointment!
                        </p>
                    </Alert>;
                reactDom.render(element, document.getElementById('alert'));
            }, (error) => {
                const element = <Alert variant="danger">
                        <Alert.Heading>Error!</Alert.Heading>
                        <p>
                            There was an error submitting your request. Error: {error.text}
                        </p>
                    </Alert>;
                reactDom.render(element, document.getElementById('alert'));
        });
    };

    return (
        <div className="App">
            <Container>
              <h1 className="App-header-alt">WAIVER</h1>
            </Container>
            <Container>
              <Row>
                <Card>
                  <p className="p-dark">In consideration of receiving a tattoo from GT Studio including its artists, associates, apprentices, agents, or any employees (hereinafter referred to as the “Tattoo Studio”) I agree to the following:</p>
                  <p className="p-dark">
                    I, have been fully informed of the inherent risks associated with getting a tattoo. Therefore,
                    I fully understand that these risks, known and unknown, can lead to injury including but not limited to: infection, scarring, difficulties in the detection of melanoma and allergic reactions to tattoo pigment, latex gloves and/or soap.
                    Having been informed of the potential risks associated with getting a tattoo I wish to proceed with the tattoo procedure and application and freely accept and expressly assume any and all risks that may arise from tattooing.
                  </p>
                  <p className="p-dark">
                    <strong>I WAIVE AND RELEASE</strong> to the fullest extent permitted by law any person of the Tattoo Studio from all liability whatsoever, including but not limited to, any and all claims or causes of action that I, my estate, heirs, executors or assigns may have for personal injury or otherwise, including any direct and/or consequential damages, which result or arise from the procedure and application of my tattoo, whether caused by the negligence or fault of either the Tattoo Studio, or otherwise.
                  </p>
                  <p className="p-dark">
                    The Tattoo Studio has given me the full opportunity to ask any question about the procedure and application of my tattoo and all of my questions, if any, have been answered to my total satisfaction.
                  </p>
                  <p className="p-dark">
                    The Tattoo Studio has given me instructions on the care of my tattoo while it's healing. I understand and will follow them. I acknowledge that it is possible that the tattoo can become infected, particularly if I do not follow the instructions given to me. If any touch-up work to the tattoo is needed due to my own negligence, I agree that the work will be done at my own expense.
                  </p>
                  <p className="p-dark">
                    I am not under the influence of alcohol or drugs, and I am voluntarily submitting to be tattooed by the Tattoo Studio without duress or coercion.
                  </p>
                  <p className="p-dark">
                    I do not suffer from diabetes, epilepsy, hemophilia, heart condition(s), nor do I take blood thinning medication. I do not have any other medical or skin condition that may interfere with the procedure, application or healing of the tattoo. I am not the recipient of an organ or bone marrow transplant or, if I am, I have taken the prescribed preventative regimen of antibiotics that is required by my doctor in advance of any invasive procedure such as tattooing or piercing. I am not pregnant or nursing. I do not have a mental impairment that may affect my judgement in getting the tattoo.
                  </p>
                  <p className="p-dark">
                    The Tattoo Studio is not responsible for the meaning or spelling of the symbol or text that I have provide to them or chosen from the flash (design) sheets.
                  </p>
                  <p className="p-dark">
                    <strong>I understand that if a rescheduling is required on clients part a 48 hour notice must be given to not forfeit deposit and be eligible for a one time reschedule.</strong>
                  </p>
                  <p className="p-dark">
                    A <strong>NON-REFUNDABLE DEPOSIT</strong> will be required when booking an appointment to secure the spot. 100% of the deposit will go towards the amount that's due on the day of the appointment. The remaining balance will be paid in CASH/CASH APP/ZELLE/APPLE PAY on the appointment day.
                  </p>
                  <p className="p-dark">
                    Deposit is <strong>NON-REFUNDABLE.</strong> Cancelling your appointment for any reason will result in losing your deposit. Please book wisely! <strong>NO CALL/NO SHOWS = NO FUTURE APPOINTMENTS AND DEPOSIT WILL BE FORFEITED!</strong>
                  </p>
                  <p className="p-dark">
                    2 days notice is required if you need to reschedule an appointment to another date and time. Only <strong>ONE</strong> time rescheduling is allowed. If you fail to do so your deposit will be forfeited.
                  </p>
                  <p className="p-dark">
                    You must arrive no later than <strong>30 minutes</strong> from your scheduled appointment time. After 30 minutes a $100 late fee would have to be paid and we would either have to reschedule to the next available date/time or cancel.
                  </p>
                  <p className="p-dark">
                    Variations in color and design may exist between the tattoo art I have selected and the actual tattoo when it is applied to my body. I also understand that over time, the colors and the clarity of my tattoo will fade due to unprotected exposure to the sun and the naturally occurring dispersion of pigment under the skin.
                  </p>
                  <p className="p-dark">
                    <strong>NO</strong> guests or pets allowed due to cross contamination and interruption. Please be respectful and understand that the artist must focus on a tattoo procedure.
                  </p>
                  <p className="p-dark">
                    A tattoo is a permanent change to my appearance and can only be removed by laser or surgical means, which can be disfiguring and/or costly and which in all likelihood will not result in the restoration of my skin to its exact appearance before being tattooed.
                  </p>
                  <p className="p-dark">
                    I release the right to any photographs taken of me and the tattoo and give consent in advance to their reproduction in print or electronic form. (For assurance, if you do not initial this provision, please inform the Tattoo Studio <strong>NOT</strong> to take any pictures of you and your completed tattoo).
                  </p>
                  <p className="p-dark">
                    I agree that the Tattoo Studio has a <strong>NO REFUND</strong> policy on tattoos, piercing and/or retail sales and I will not ask for a refund for any reason whatsoever.
                  </p>
                  <p className="p-dark">
                    I agree to reimburse the Tattoo Studio for any attorneys' fees and costs incurred in any legal action I bring against the Tattoo Studio and in which either the Artist of the Tattoo Studio is the prevailing party. I agree that the courts of located in the County of Fresno within the State ofCalifornia shall have jurisdiction and venue over me and shall have exclusive jurisdiction for the purposes of litigating any dispute arising out of or related to this agreement.
                  </p>
                  <p className="p-dark">
                    I acknowledge that I have been given adequate opportunity to read and understand this document that it was not presented to me at the last minute and grasp that I am signing a legal contract waiving certain rights to recover damages against the Tattoo Studio.
                  </p>
                  <p className="p-dark">
                    If any provision, section, subsection, clause or phrase of this release is found to be unenforceable or invalid, that portion shall be severed from this contract. The remainder of this contract will then be construed as though the unenforceable portion had never been contained in this document.
                  </p>
                  <p className="p-dark">
                    I hereby declare that I am of legal age (and have provided valid proof of age and identification) and am competent to sign this Agreement.
                  </p>
                  <p className="p-dark">
                    <strong>I HAVE READ THE AGREEMENT, I UNDERSTAND IT, AND I AGREE TO BE BOUND BY IT.</strong>
                  </p>
                  <form ref={form} onSubmit={sendEmail}>
                  <Col>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="fromName">
                          <InputGroup className="mb-2">
                            <InputGroup.Text>Name:</InputGroup.Text>
                            <Form.Control name="name" type="text" placeholder="Your Name" required />
                          </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} controlId="formDate">
                          <InputGroup className="mb-2">
                            <InputGroup.Text>Date:</InputGroup.Text>
                            <Form.Control name="date" type="date" placeholder="01/01/23" required />
                          </InputGroup>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formAddress">
                        <InputGroup className="mb-2">
                            <InputGroup.Text>Address:</InputGroup.Text>
                            <Form.Control name="address" type="text" placeholder="123 West Main St" required />
                          </InputGroup>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formCity">
                        <InputGroup className="mb-2">
                            <InputGroup.Text>City:</InputGroup.Text>
                            <Form.Control name="city" type="text" placeholder="Fresno" required />
                          </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} controlId="fromState">
                        <InputGroup className="mb-2">
                            <InputGroup.Text>State:</InputGroup.Text>
                            <Form.Control name="state" type="text" placeholder="CA" required />
                          </InputGroup>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="fromIdentification">
                        <InputGroup className="mb-2">
                            <InputGroup.Text>Identification:</InputGroup.Text>
                            <Form.Control type="file" name="identification" required />
                          </InputGroup>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <div className="button-container">
                        <Button variant="light" type="submit" size="lg" className="btn btn-primary btn-lg padded-button">
                            <FontAwesomeIcon icon={faCheckCircle} fixedWidth/> Acknowledge and Submit
                          </Button>
                        </div>
                    </Row>
                  </Col>
                  </form>
                </Card>
              </Row>
            </Container>
          </div>
    );
};

export default Waiver;
