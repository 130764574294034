import { React } from 'react';
import { Container, Row, Col, Card, Button, Alert } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import reactDom from 'react-dom';

import './../../App.css';

export const Merch = () => {
      document.title = 'krucerstattoos - merchandise';

      const orderMerch = (e) => {
          e.preventDefault();
          const element = <Alert variant="info">
                  <Alert.Heading>See me in the shop!</Alert.Heading>
                  <p>
                      I have plenty of merch in the shop, come see me to get yours.
                  </p>
              </Alert>;
          reactDom.render(element, document.getElementById('alert'));
      };

      return (
        <div className="App">
          <Container>
            <h1 className="App-header-alt">MERCHANDISE</h1>
            <Row>
                <Col>
                    <div id="alert"></div>
                </Col>
            </Row>
            <Row>
              <Col sm={4}>
                  <Card bg="secondary" border="success">
                      <Card.Body>
                          <Card.Title>Hoody</Card.Title>
                          <Card.Img variant="top" src="/krucer.jpeg" className="img-thumbnail d-block" />
                          <Card.Text>
                              Signature hoody with the Krucers Tattoos Logo
                          </Card.Text>
                          <div className="button-container">
                              <Button variant="success" type="submit" size="xs" className="padded-button" onClick={orderMerch}>
                                  <FontAwesomeIcon icon={faShoppingCart} fixedWidth/> Purchase
                              </Button>
                          </div>
                      </Card.Body>
                  </Card>
              </Col>
              <hr className="clearfix w-100 d-md-none pb-0"/>
              <Col sm={4}>
                  <Card bg="secondary" border="success">
                      <Card.Body>
                          <Card.Title>Tee</Card.Title>
                          <Card.Img variant="top" src="/krucer.jpeg" className="img-thumbnail d-block" />
                          <Card.Text>
                              Damn Fine Tee with the Krucers Tattoos Logo
                          </Card.Text>
                          <div className="button-container">
                              <Button variant="success" type="submit" size="xs" className="padded-button" onClick={orderMerch}>
                                  <FontAwesomeIcon icon={faShoppingCart} fixedWidth/> Purchase
                              </Button>
                          </div>
                      </Card.Body>
                  </Card>
              </Col>
              <hr className="clearfix w-100 d-md-none pb-0"/>
              <Col sm={4}>
                  <Card bg="secondary" border="danger">
                      <Card.Body>
                          <Card.Title>Apron</Card.Title>
                          <Card.Img variant="top" src="/krucer.jpeg" className="img-thumbnail d-block" />
                          <Card.Text>
                              Krucers Signature Apron - keep that drip fresh
                          </Card.Text>
                          <div className="button-container">
                              <Button variant="danger" type="submit" size="xs" className="padded-button" disabled>
                                  <FontAwesomeIcon icon={faCircleXmark} fixedWidth/> Out of Stock
                              </Button>
                          </div>
                      </Card.Body>
                  </Card>
              </Col>
            </Row>
          </Container>
        </div>
      );
}

export default Merch;
