import React from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSprayCan, faGlobeAmericas, faDollarSign, faHome, faPalette, faCalendar, faTshirt, faPhoneAlt, faHeart } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons"

import './../../App.css';

class Footer extends React.Component {
    render() {
        return (
            <div className="App">
            <Container>
                <>
                <footer className="page-footer font-small pt-4 footer-sticky">
                    <div className="container-fluid text-center text-md-left">
                        <div className="row">
                            <div className="col-md-6 mt-md-0 mt-3">
                                <h5 className="text-uppercase"><FontAwesomeIcon icon={faSprayCan} fixedWidth/> krucerstattoos</h5>
                                <h6><FontAwesomeIcon icon={faGlobeAmericas} fixedWidth/> Fresno, CA.</h6>
                                <h6><a href="sms://+15594969155" className='App-navigation-link-small'><FontAwesomeIcon icon={faPhoneAlt} fixedWidth /> (559) 496-9155</a></h6>
                                <ul className="inline-list">
                                    <li>
                                        Appointment Only
                                    </li>
                                    <li>
                                        Strong Fine Lines
                                    </li>
                                    <li>
                                        Color, Black and Grey, Custom Script, Coverups
                                    </li>
                                    <li>
                                        Private Tattoo Studio
                                    </li>
                                    <li>
                                        Non-refundable Deposit Required
                                    </li>
                                </ul>
                            </div>

                            <hr className="clearfix w-100 d-md-none pb-0"/>

                            <div className="col-md-2 mb-md-0 mb-3">
                                <h5 className="text-uppercase">Sitemap</h5>
                                <ul className="list-unstyled">
                                    <li><a href="/" className="App-navigation-link-small"><FontAwesomeIcon icon={faHome} fixedWidth/> Home</a></li>
                                    <li><a href="/work" className="App-navigation-link-small"><FontAwesomeIcon icon={faPalette} fixedWidth/> Work</a></li>
                                    <li><a href="/schedule" className="App-navigation-link-small"><FontAwesomeIcon icon={faCalendar} fixedWidth/> Schedule</a></li>
                                    <li><a href="/merch" className="App-navigation-link-small"><FontAwesomeIcon icon={faTshirt} fixedWidth/> Merch</a></li>
                                    <li><a href="/payment" className="App-navigation-link-small"><FontAwesomeIcon icon={faDollarSign} fixedWidth/> Payment</a></li>
                                </ul>
                            </div>

                            <hr className="clearfix w-100 d-md-none pb-0"/>

                            <div className="col-md-2 mb-md-0 mb-3">
                                <h5 className="text-uppercase">Socials</h5>
                                <ul className="list-unstyled">
                                    <li><a href="https://www.instagram.com/krucerstattoos/" className="App-navigation-link-small" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} fixedWidth/> Instagram</a></li>
                                    <li><a href="https://twitter.com/krucerstattoos" className="App-navigation-link-small" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTwitter} fixedWidth/> Twitter</a></li>
                                    <li><a href="https://www.facebook.com/krucerstattoos/" className="App-navigation-link-small" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook} fixedWidth/> Facebook</a></li>
                                </ul>
                            </div>

                            <hr className="clearfix w-100 d-md-none pb-0"/>

                            <div className="col-md-2 mb-md-0 mb-3">
                                <img src="krucer.jpeg" alt="Krucer Himself" className="img-thumbnail rounded-pill d-block footer-image" />
                            </div>
                        </div>
                        <hr className="clearfix w-100 pb-0"/>
                        <div className="row">
                            <div className="footer-copyright button-container p-3">
                                <span>© {new Date().getFullYear()} krucerstattoos | Inked with <a href="https://www.flude.dev"><span className="red"><FontAwesomeIcon icon={faHeart} size="xs" fixedWidth /></span></a></span>
                            </div>
                        </div>
                    </div>
                </footer>
                </>
            </Container>
            </div>
        );
    }
}

export default Footer;
