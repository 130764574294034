import './App.css';
import React from 'react';
import Home from "./component/home/Home";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSprayCan, faDollarSign, faHome, faPalette, faCalendar, faTshirt, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import Schedule from './component/book/Schedule';
import Work from './component/work/Work';
import Payment from './component/payment/Payment';
import Footer from './component/footer/Footer';
import Merch from './component/merch/Merch';
import Waiver from './component/waiver/Waiver';


class App extends React.Component {
  render() {
    return (
      <Router>
        <Navbar className="App-navigation navbar-light" expand="xl">
          <Navbar.Brand>
            <Link to="/" className="App-navigation-link dark-logo">
              <span><FontAwesomeIcon icon={faSprayCan} size="xs" /> <strong>krucerstattoos</strong></span>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Link to="/" className="App-navigation-link"><span><FontAwesomeIcon icon={faHome} fixedWidth size="xs" /></span> home</Link>
              <Link to="/work" className="App-navigation-link"><span><FontAwesomeIcon icon={faPalette} fixedWidth size="xs" /></span> work</Link>
              <Link to="/schedule" className="App-navigation-link"><span><FontAwesomeIcon icon={faCalendar} fixedWidth size="xs" /></span> schedule</Link>
              <Link to="/merch" className="App-navigation-link"><span><FontAwesomeIcon icon={faTshirt} fixedWidth size="xs" /></span> merch</Link>
              <Link to="/waiver" className="App-navigation-link"><span><FontAwesomeIcon icon={faCheckCircle} fixedWidth size="xs" /></span> waiver</Link>
            </Nav>
            <Nav className="ml-auto">
              <>
              <a href="https://www.instagram.com/krucerstattoos/" className="App-navigation-link" target="_blank" rel="noreferrer"><span><FontAwesomeIcon icon={faInstagram} size="xs" fixedWidth/></span> instagram</a>
              <a href="/payment" className="App-navigation-link" target="_blank" rel="noreferrer"><span><FontAwesomeIcon icon={faDollarSign} size="xs" fixedWidth/></span> payment</a>
              </>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Switch>
          <Route path="/payment">
            <Payment />
          </Route>
          <Route path="/merch">
            <Merch />
          </Route>
          <Route path="/work">
            <Work />
          </Route>
          <Route path="/schedule">
            <Schedule />
          </Route>
          <Route path="/waiver">
            <Waiver />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </Router>
      
    );
  }
}

export default App;
