import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Container, Row, Col, Form, Button, InputGroup, Alert } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill, faCalendar, faSms } from "@fortawesome/free-solid-svg-icons";
import './../../App.css';
import reactDom from 'react-dom';

export const Schedule = () => {
    document.title = 'krucerstattoos - schedule';
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_v3dzmdd', 'template_1wh7cpl', form.current, 'xanr2mTWRowkAJsq3')
            .then((result) => {
                const element = <Alert variant="success">
                        <Alert.Heading>Success!</Alert.Heading>
                        <p>
                            I have received your request to get on the schedule, I will be in touch as soon as I can!
                        </p>
                    </Alert>;
                reactDom.render(element, document.getElementById('alert'));
            }, (error) => {
                const element = <Alert variant="danger">
                        <Alert.Heading>Error!</Alert.Heading>
                        <p>
                            There was an error submitting your request. Error: {error.text}
                        </p>
                    </Alert>;
                reactDom.render(element, document.getElementById('alert'));
        });
    };

    return (
        <div className="">
        <Container>
            <Row>
            <Col>
                <h1 className="App-header-alt">SO YOU WANT A TATTOO?</h1>
            </Col>
            </Row>
            <Row>
                <Col>
                    <div id="alert"></div>
                </Col>
            </Row>
            <Row>
                <hr />
            </Row>
            <Row>
            <Col>
                <form ref={form} onSubmit={sendEmail}>
                    <Row className="mb-3 bt-3">
                        <Form.Group as={Col} controlId="formYourName">
                        <Form.Label>Your Name</Form.Label>
                        <Form.Control name="name" type="text" placeholder="Krucer" required />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formYourEmail">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control name="email" type="email" placeholder="krucer@krucerstattoos.com" required />
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formBudget">
                            <Form.Label>Budget ($)</Form.Label>
                            <InputGroup className="mb-2">
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control name="budget" type="number" min="1.00" step="1.00" placeholder="How much do you want to spend?" required />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formPhoneNumber">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control name="phone" type="tel" placeholder="555-123-4543" required />
                        </Form.Group>
                    </Row>

                    <Form.Group className="mb-3" controlId="formDescribeWork">
                        <Form.Label>What do you want done?</Form.Label>
                            <Form.Control
                            as="textarea"
                            name="what"
                            placeholder="Describe the work, what is it that you want? Is there lettering involved? If so, what style? Include information like where on your body it will go and what size it needs to be"
                            style={{ height: '150px' }}
                            required
                            />
                    </Form.Group>

                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formAvailability">
                        <Form.Label>Availability</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="when"
                            placeholder="What days and times are you available?"
                            style={{ height: '100px' }}
                            required
                            />
                        </Form.Group>
                    </Row>
                    <div className="my-3 button-container text-danger">
                        <strong>A non-refundabe deposit is required to get on the schedule. Any information submitted at krucerstattoos.com will be used to contact you.</strong>
                    </div>
                    <div className="button-container">
                        <Button variant="light" type="submit" size="lg" className="padded-button">
                        <FontAwesomeIcon icon={faCalendar} fixedWidth/> Request
                        </Button>
                        <a href="sms://+15594969155" className="btn btn-success btn-lg padded-button">
                        <FontAwesomeIcon icon={faSms} fixedWidth/> Text Me
                        </a>
                        <a href="/payment" className="btn btn-primary btn-lg padded-button">
                        <FontAwesomeIcon icon={faMoneyBill} fixedWidth/>  Deposit
                        </a>
                    </div>
                </form>
            </Col>
            </Row>
        </Container>
        </div>
    );
};

export default Schedule;
