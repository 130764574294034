import React from 'react';
import { Container, Row, Card, Col } from "react-bootstrap"

import './../../App.css';

class Work extends React.Component {
    render() {
        document.title = 'krucerstattoos - work';

        return (
          <div className="App">
            <Container>
              <h1 className="App-header-alt">WORK</h1>
            </Container>
            <Container>
              <Row>
                <Col sm={4}>
                  <Card>
                    <Card.Img variant="top" src="a.jpeg" />
                  </Card>
                </Col>
                <hr className="clearfix w-100 d-md-none pb-0"/>
                <Col sm={4}>
                  <Card>
                    <Card.Img variant="top" src="b.jpeg" />
                  </Card>
                </Col>
                <hr className="clearfix w-100 d-md-none pb-0"/>
                <Col sm={4}>
                  <Card>
                    <Card.Img variant="top" src="f.jpeg" />
                  </Card>
                </Col>
                <hr className="clearfix w-100 d-md-none pb-0"/>
              </Row>
            </Container>
            <Container className="container-spacer">
              <Row>
                <Col sm={4}>
                  <Card>
                    <Card.Img variant="top" src="e.jpeg" />
                  </Card>
                </Col>
                <hr className="clearfix w-100 d-md-none pb-0"/>
                <Col sm={4}>
                  <Card>
                    <Card.Img variant="top" src="c.jpeg" />
                  </Card>
                </Col>
                <hr className="clearfix w-100 d-md-none pb-0"/>
                <Col sm={4}>
                  <Card>
                    <Card.Img variant="top" src="g.jpeg" />
                  </Card>
                </Col>
              </Row>
            </Container>
            <Container className="container-spacer">
              <Row>
                <Col sm={4}>
                  <Card>
                    <Card.Img variant="top" src="i.jpg" />
                  </Card>
                </Col>
                <hr className="clearfix w-100 d-md-none pb-0"/>
                <Col sm={4}>
                  <Card>
                    <Card.Img variant="top" src="j.jpg" />
                  </Card>
                </Col>
                <hr className="clearfix w-100 d-md-none pb-0"/>
                <Col sm={4}>
                  <Card>
                    <Card.Img variant="top" src="k.jpg" />
                  </Card>
                </Col>
              </Row>
            </Container>
            <Container className="container-spacer">
              <Row>
                <Col sm={4}>
                  <Card>
                    <Card.Img variant="top" src="l.jpg" />
                  </Card>
                </Col>
                <hr className="clearfix w-100 d-md-none pb-0"/>
                <Col sm={4}>
                  <Card>
                    <Card.Img variant="top" src="m.jpg" />
                  </Card>
                </Col>
                <hr className="clearfix w-100 d-md-none pb-0"/>
                <Col sm={4}>
                  <Card>
                    <Card.Img variant="top" src="n.jpg" />
                  </Card>
                </Col>
              </Row>
            </Container>
            <Container className="container-spacer">
              <Row>
                <Col sm={4}>
                  <Card>
                    <Card.Img variant="top" src="o.jpg" />
                  </Card>
                </Col>
                <hr className="clearfix w-100 d-md-none pb-0"/>
                <Col sm={4}>
                  <Card>
                    <Card.Img variant="top" src="p.jpg" />
                  </Card>
                </Col>
                <hr className="clearfix w-100 d-md-none pb-0"/>
                <Col sm={4}>
                  <Card>
                    <Card.Img variant="top" src="s.jpeg" />
                  </Card>
                </Col>
              </Row>
            </Container>
            <Container className="container-spacer">
              <Row>
                <Col sm={4}>
                  <Card>
                    <Card.Img variant="top" src="q.jpg" />
                  </Card>
                </Col>
                <hr className="clearfix w-100 d-md-none pb-0"/>
                <Col sm={4}>
                  <Card>
                    <Card.Img variant="top" src="r.jpg" />
                  </Card>
                </Col>
                <hr className="clearfix w-100 d-md-none pb-0"/>
                <Col sm={4}>
                  <Card>
                    <Card.Img variant="top" src="g.jpeg" />
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        );
    }
}

export default Work;
